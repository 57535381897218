import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import BarberCustomers from './components/BarberCustomers';

function App() {
  return (
    <div className="app" >
      <Header />
      <Routes>
        <Route path="/" element={<BarberCustomers />} />
        <Route path="/login" element={<LoginForm />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
