import axios from 'axios';
// const baseUrl = 'http://localhost:8891';
const baseUrl = 'https://barberapi.thedogpeoplellc.com/';
const api = axios.create({
  baseURL: baseUrl
})
api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const loginUser = async (loginData) => {
  const resp = await api.post('services/auth/login', loginData,
    {
      headers: {
        "traceId": "benfromws",
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
  localStorage.setItem('authToken', resp.data.token);
  api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
  return resp.data.user
}
// export const changePassword = async (loginData) => {
//   const resp = await api.post('services/auth/resetpassword', loginData)
//   localStorage.setItem('authToken', resp.data.token);
//   api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
//   return resp.data
// }
// export const resetPassword = async (resetToken, data) => {
//   const resp = await api.put(`/password_resets/${resetToken}`, data)
//   return JSON.stringify(resp.data);
// }
// export const resetPasswordInit = async (data) => {
//   const resp = await api.put(`/password_resets/new`, data)
//   return JSON.stringify(resp.data);
// }

export const registerUser = async (registerData) => {
  const resp = await api.post('/services/auth/register', registerData)
  localStorage.setItem('authToken', resp.data.token);
  api.defaults.headers.common.authorization = `Bearer ${resp.data.token}`
  return resp.data.user
}

export const verifyUser = async () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    api.defaults.headers.common.authorization = `Bearer ${token}`
    const resp = await api.get('services/auth/verify');
    return resp.data
  }
  return false
}

export const addBarberCustomerAPI = async (customer) => {
  const resp = await api.post(`/barber/customers`, customer);
  return resp?.data?.insertId;
}
export const updateBarberCustomerAPI = async (customer) => {
  const resp = await api.put(`/barber/customers/${customer.idcustomer}`, customer);
  return resp.data;
}
export const deleteBarberCustomerAPI = async (customer) => {
  const resp = await api.delete(`/barber/customers/${customer}`);
  return resp.data;
}
export const getBarberCustomersAPI = async () => {
  const resp = await api.get(`/barber/customers`);
  return resp.data;
}
export const getBarberCustomerAPI = async (customerId) => {
  const resp = await api.get(`/barber/customers/${customerId}`);
  return resp.data;
}