import React from 'react';
export default function Footer(props) {

  return (
    <footer>
      <div id="footer-group">
        <div id="footer-links">
        </div>
      </div>
      <p id='copyright'>Barber Shop 2024</p>
    </footer>
  )
}

