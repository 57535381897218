import React, { useState, useEffect, useRef } from 'react'
import MaterialTable from 'material-table';
import { verifyUser, getBarberCustomersAPI, addBarberCustomerAPI, updateBarberCustomerAPI, deleteBarberCustomerAPI } from '../services/api-helper';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import exportFromJSON from 'export-from-json'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from "@material-ui/icons";
import { forwardRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

function BarberCustomers(props) {
  let navigate = useNavigate();
  const fileName = 'BarberShopCustomers';
  const exportType = 'csv';

  const getBarberCustomers = async () => {
    const verify = async () => {
      try {
        const user = await verifyUser();
        if (!user) {
          navigate('/login');
        }
      } catch (err) {
        console.log('err=', err);
        navigate('/login');
      }
    }
    if (verify()) {
      const bc = await getBarberCustomersAPI();
      setData(bc);
    }
  }
  const [columns] = useState([
    { title: 'idCustomer', field: 'idcustomer', type: "numeric", hidden: true},
    { title: 'First Name', field: 'firstname' },
    { title: 'Last Name', field: 'lastname' },
    { title: 'Phone Number', field: 'phonenumber' },
    {
      title: 'Date Of Service',
      field: 'service_date',
      initialEditValue: new Date(),
      type: "date",
      editComponent: ({ value, onRowDataChange, rowData }) => (
        <DatePicker dateFormat="MM-dd-yyyy" selected={value} onChange={(event) => {

          onRowDataChange({
            ...rowData,
            service_date: event
          });

        }} />
      )
    },
    { title: 'Price of Service', field: 'price' },
    { title: 'Description of Service', field: 'description' }
  ]);

  useEffect(() => {
    getBarberCustomers();
  }, [props]);
  const [data, setData] = useState([]);

  const tableRef = useRef();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const defaultMaterialTheme = createTheme();

  const handleRowAdd = (newData, resolve) => {
    addBarberCustomerAPI(newData)
      .then(res => {
        newData.idcustomer = res;
        setData([...data, newData]);
        resolve();
      })
      .catch(error => {
        resolve()
      })
  }
  const exportExcel = (data) => {
    exportFromJSON({ data, fileName, exportType, fields: { "idcustomer": "CustomerId", "firstname": "First Name", "lastname": "Last Name","phonenumber": "Phone Number","service_date": "Date Of Service","price":"Price of Service", "description": "Description" } });
  }
  return (
    <div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          options={{
            search: true,
            pageSizeOptions: [5, 10, 25, 50],
            pageSize: 10,
            exportButton: true,
            exportCsv: (columns, data) => {
              exportExcel(data);
            },
            exportPdf: (columns, data) => {
              alert('Unavailable');
            }
          }}
          title="Customers"
          icons={tableIcons}
          tableRef={tableRef}
          columns={columns}
          data={data}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                newData.service_date = moment(new Date(newData.service_date)).format("YYYY-MM-DD")
                handleRowAdd(newData, resolve)
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  updateBarberCustomerAPI(newData);
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                }, 1000)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteBarberCustomerAPI(oldData.idcustomer);
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                }, 1000)
              }),

          }
          }
        />
      </ThemeProvider>
    </div>
  )
}

export default BarberCustomers;