import React from 'react';
import tdp from '../images/bb5.jpg'
export default function Header(props) {

  return (
    <header id='header'>
      <img id='tdpImg' src={tdp} alt=''></img>
    </header>
  )
}

